import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {useGraphql} from '../hooks/useGraphql';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));
export default function SimplePaper() {
    const classes = useStyles();
    const {org, user, getUser} = useGraphql()
    useEffect(() => {
        getUser()
    },[])
    console.log(user, 'does this not get')
    const connectBank = () => {
        window.location = `https://connect.stripe.com/express/oauth/authorize?client_id=ca_HQ4A901fcZvBtmbeK3WCrO7CcvCZhQ8s&state=${org.id}&stripe_user[email]=${user ? user.email : ''}`;
    };
    return (
        <Grid container className={classes.root}>
            <Grid item  xs={8}>
                {org && <Paper style={{padding:12}}>
                    {!org.stripeAccountId ? <h2>Connect your bank account for receiving money.</h2> : <h2>You're all set! FlexDesk will deposit funds directly into your account.</h2>}
                    <Button
                        style={{maxWidth:300}}
                        variant="contained"
                        type="button"
                        color="primary"
                        fullWidth
                        disabled={org.stripeAccountId}
                        onClick={connectBank}
                    >
                        {/* {loading && <CircularProgress size={25} thickness={2} />} */}
                        {!org.stripeAccountId ? 'Connect Bank Account' : 'Bank Account Connected ✅'}
                    </Button>
                </Paper>}
            </Grid>
        </Grid>
    );
}
