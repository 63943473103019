import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploader from 'react-firebase-file-uploader';
import { useForm, useFormState } from 'react-final-form';
import auth from '../hooks/useAuth';
import shortid from 'shortid';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import ImageGallery from './ImageGallery';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    fileDrop: {
        width: 300,
        height: 100,
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    gridList: {
        flexWrap: 'nowrap',
        height: 300,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));
export default ({ formData }) => {
    const form = useForm();
    const [images, setImages] = useState(formData.images || []);
    const [processingImage, setProcessingImage] = useState(false);

    const classes = useStyles();
    useEffect(() => {
        form.change('images', images);
    }, [images]);
    const onDrop = acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
          
            var metadata = {
                contentType: file.type,
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            setProcessingImage(true)
            reader.onload = async () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                console.log(binaryStr);
                const filename = shortid.generate();
                const result = await firebase
                    .storage()
                    .ref()
                    .child(`${filename}.${file.type.split('/')[1]}`)
                    .put(binaryStr, metadata);

               
                
                setTimeout(() => {
                    setImages([
                        ...images,
                        `https://firebasestorage.googleapis.com/v0/b/flexdesk-bfd81.appspot.com/o/${filename}_1000x1000.${
                            file.type.split('/')[1]
                        }?alt=media`,
                    ]);
                    setProcessingImage(false)
                }, 5000);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    // return (
    //   <div>
    //     <div style={{width:'50%'}}>
    //     <DropzoneArea
    //       onChange={onDrop}
    //       />
    //     </div>
    //   <div >
    //   {images.length > 0 &&<ImageGallery style={{height:'auto', width:'100%'}} form={form} images={images} setImages={setImages}/>}
    //   </div>

    // </div>
    // )

    return (
        <div>
            <div style={{ width: '100%', height: 100 }} {...getRootProps()}>
                <input {...getInputProps()} />
                <Paper className={classes.fileDrop}>
                    {isDragActive ? (
                        <p>Drop the images here ...</p>
                    ) : (
                        <p>
                            Drag 'n' drop some images here, or click to select
                            an image
                        </p>
                    )}
                </Paper>
            </div>
            {processingImage && <div>
                <CircularProgress /><p>Uploading image...</p>
            </div>}
            {images.length > 0 && <ImageGallery
                style={{ height: 'auto', width: '100%' }}
                form={form}
                images={images}
                setImages={setImages}
            />}
        </div>
    );
};
