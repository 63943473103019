import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import {useGraphql} from './hooks/useGraphql'
// Add your Firebase credentials
try {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
} catch (err) {}

let refreshInterval

if(!refreshInterval) {
  refreshInterval = setInterval(() => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          localStorage.setItem('flex-token', idToken);
        })
        .catch(() => {
          localStorage.removeItem('flex-token')
        })
    }
  }, 120000)
}


const authProvider = {
  login: ({ username, password }) => firebase
    .auth()
    .signInWithEmailAndPassword(username, password)
    .then(async (response) => {
      const token = await response.user.getIdToken();
      localStorage.setItem('flex-token', token);
      
    }),
  logout: (params) => firebase
    .auth()
    .signOut()
    .then(() => { localStorage.removeItem('flex-token'); }),
  checkAuth: () => {
    return fetch('https://flexdesk-firebase.glitch.me/firebase/webhook', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('flex-token')}`
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(response => response.json())
    .then(data => {
      console.log(data, 'webhook data')
      if(data['X-Hasura-User-Id']) {
        window.analytics.identify(data['X-Hasura-User-Id']);
        localStorage.setItem('flex-uid', data['X-Hasura-User-Id'])
      }
      if(data['x-hasura-role'] === 'anonymous') {
        return Promise.reject()
      } else {
        return Promise.resolve()
      }
    }).catch(function(err) {
      console.log(err, 'webhook error')
    })
  },
  checkError: (error) => Promise.resolve(),
  getPermissions: (params) => Promise.resolve(),

};

export default authProvider;
