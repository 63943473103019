import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddressAutocomplete from '../../components/AddressAutocomplete'
import ImageUpload from '../../components/ImageUpload'
import Amenities from '../../components/Amenities'  
import  {FormDataConsumer} from 'react-admin'
import { Form, Field } from 'react-final-form'
import { useMutation, useQuery, Loading, Error, useDataProvider } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useAuthenticated } from 'react-admin';
import AvailabilityModule from '../../components/AvailabilityModule';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        
        flex:1
      },
    },
    fill: {
        flex:1
    },
    small:{
        width: 100
    },
    padding: {
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width:'100%',
        flex:1 
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

const required = value => (value ? undefined : 'Required')


export default function CenteredGrid({ setDesks, officeId, offices }) {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const [error, setError] = useState();
    const [organizations, setOrganizations] = useState([])
    useEffect(() => {
        dataProvider.getList('organizations', { pagination: { page: 1, perPage: 5 }, sort: { field: 'id', order: 'ASC' }, filter: {  }, })
            .then(({ data }) => {
                setOrganizations(data);
                setLoading(false);
            })
            .catch(error => {
       
                setLoading(false);
            })
    }, []);
    
    const [mutate, { loading:loading2, data:desk }] = useMutation();
    const create = payload => mutate({
        type: 'create',
        resource: 'desks',
        payload: {data:payload}
    })
    const onSubmit = async (data) => {
        console.log(data, 'data?')
        data.officeId = offices[0].id
        await create(data)
       
    }

    useEffect(() => {
        console.log(desk, 'desk')
        setDesks([desk])
    },[desk])
    const validate = () => {

    }
    if (loading || loading2) return <Loading />;
    if (error) return <Error />;
  return (  
    <div className={classes.root}>

        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={3} justify="center">
            <Grid item xs={10} xl={6}>
            <Field name="name" >
                    {({ input, meta }) => (
                        <TextField
                        
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            fullWidth
                            label="Name (internal use)"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'A name for this desk, for internal use.'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="type" >
                    {({ input, meta }) => (
                        <FormControl className={classes.formControl} error={meta.error && meta.touched }>
                            <InputLabel id="desk-type-error-label">Desk Type</InputLabel>
                            <Select
                            labelId="desk-type-error-label"
                            id="desk-type-error"
                        
                            variant="filled"
                            {...input}
                            
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Private Desk'}>Private Desk</MenuItem>
                                <MenuItem value={'Shared Desk'}>Shared Desk</MenuItem>
                                <MenuItem value={'Shared Desk'}>Open Desk</MenuItem>
                                <MenuItem value={'Cubicle'}>Cubicle</MenuItem>
                                <MenuItem value={'Sofa'}>Sofa</MenuItem>
                                <MenuItem value={'Private Office'}>Private Office</MenuItem>
                            </Select>
                            {meta.error && meta.touched &&<FormHelperText>Error</FormHelperText>}
                        </FormControl>
                    )}
                </Field>
                <Field name="width" >
                    {({ input, meta }) => (
                        <TextField
                        className={classes.small}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Width"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': ''}
                            variant="filled"
                        />
                    )}
                </Field>
                <Field name="length" >
                    {({ input, meta }) => (
                        <TextField
                        className={classes.small}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Length"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': ''}
                            variant="filled"
                        />
                    )}
                </Field>
                <Field name="height" >
                    {({ input, meta }) => (
                        <TextField
                        className={classes.small}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Height"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': ''}
                            variant="filled"
                        />
                    )}
                </Field>

               

                <Field name="count" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="count-helper-text"
                            fullWidth
                            label="Count"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'How many of these desks are available?'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="price" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="count-helper-text"
                            fullWidth
                            label="Price (per day)"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'How much should this desk cost to rent per day?'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="description" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Description"
                            multiline
                            fullWidth
                            rowsMax={4}
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'Please provide important or exciting desk details'}
                            variant="filled"
                        />
                    )}
                </Field>
                <Grid
                    container
                    direction="row"
                    justify="start"
                    alignItems="center"
                    className={classes.padding}
                    >
                    <AvailabilityModule />
                </Grid> 
                
            </Grid>
            <Grid item xs={10} xl={6}>
            <InputLabel id="desk-type-error-label" style={{marginBottom: 5, marginTop: 5}}>Images of desk</InputLabel>
                <FormControl className={classes.formControl}>
                    
                    <FormDataConsumer>
                        {formDataProps => (
                            <ImageUpload {...formDataProps} className={classes.padding}/>
                        
                        )}
                        
                    </FormDataConsumer>
                </FormControl>
                <Amenities amenities={[
                    'Standing Desk',
                    'Adjustable Chair',
                    'Second Monitor',
                    'Beautiful View', 
                    'Wireless Charger', 
                    'Ergonomic Chair', 
                    'Standing Desk Floor Mat', 
                    'Backpack Hook', 
                    'Laptop Stand',
                    'Ethernet Cable',
                    'Extra Power Outlets'
                ]}/>
            </Grid>
            
        </Grid>
            <Grid container
                    direction="row"
                    justify="flex-start"
                    className={classes.padding}
                    alignItems="center" >
                <Button loading={loading} size="medium" type="submit" variant="contained" color="primary" className={classes.margin}>
                    Save Desk
                </Button>
            </Grid>
        </form>
        )} />
    </div>
  );
}
