import React, { useState } from 'react'
import {
  Create,
  EditController,
  EditView,
  SimpleForm,
  TextInput,
  DateInput,
  NumberField,
  NumberInput,
  TextField,
  DateField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  required
} from 'react-admin';
import Grid from '@material-ui/core/Grid';

export const MembershipCreate = (props) => {
  const [membershipType, setMembershipType] = useState()

  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container spacing={3} style={{ width: '100%' }}>
          <Grid item xs={12} container direction={'column'}>
            <SelectInput
              label="Membership type"
              validate={[required()]}
              source="type"
              onChange={({ target }) => {
                setMembershipType(target.value)
              }}
              choices={[
                { id: 'month_to_month', name: 'Month to month' },
                { id: 'fixed_term', name: 'Fixed term' },
              ]}
            />
            {membershipType && membershipType === "month_to_month" && (
              <DateInput
                source="startDate"
                label="Start date"
                validate={[required()]}
              />
            )}
            {membershipType && membershipType === "fixed_term" && (
              <>
                <DateInput
                  source="startDate"
                  label="Start date"
                  validate={[required()]}
                />
                <DateInput
                  source="endDate"
                  label="End date"
                  validate={[required()]}
                />
              </>
            )}
            <NumberInput
              source="rate"
              label="Rate"
              validate={[required()]}
            />
            <ReferenceInput
              source="companyId"
              validate={[required()]}
              reference="Company"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="officeId"
              validate={[required()]}
              reference="Office"
            >
              <SelectInput optionText="address" />
            </ReferenceInput>
            <SelectInput
              label="Inventory type"
              validate={[required()]}
              source="inventoryType"
              choices={[
                { id: 'single_person_desk', name: 'Single-person desk' },
                { id: 'single_person_office', name: 'Single-person office' },
                { id: 'multi_person_office', name: 'Multi-person office' },
              ]}
            />
            <TextInput
              label="Users"
              source="users"
              multiline
              validate={[required()]}
            />
            <TextInput
              label="Additional notes"
              source="additionalNotes"
              multiline
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export const MembershipEdit = (props) => {
  const [membershipType, setMembershipType] = useState()

  return (
    <EditController {...props}>
      {controllerProps =>
        <EditView {...props} {...controllerProps}>
          <SimpleForm>
            <Grid container spacing={3} style={{ width: '100%' }}>
              <Grid item xs={12} container direction={'column'}>
                <SelectInput
                  label="Membership type"
                  validate={[required()]}
                  source="type"
                  onChange={({ target }) => {
                    setMembershipType(target.value)
                  }}
                  choices={[
                    { id: 'month_to_month', name: 'Month to month' },
                    { id: 'fixed_term', name: 'Fixed term' },
                  ]}
                />
                {(!membershipType && controllerProps.record && (controllerProps.record.type === "month_to_month")) && (
                  <DateInput
                    source="startDate"
                    label="Start date"
                    validate={[required()]}
                  />
                )}
                {membershipType === "month_to_month" && (
                  <DateInput
                    source="startDate"
                    label="Start date"
                    validate={[required()]}
                  />
                )}
                {(!membershipType && controllerProps.record && (controllerProps.record.type === "fixed_term")) && (
                  <>
                    <DateInput
                      source="startDate"
                      label="Start date"
                      validate={[required()]}
                    />
                    <DateInput
                      source="endDate"
                      label="End date"
                      validate={[required()]}
                    />
                  </>
                )}
                {membershipType === "fixed_term" && (
                  <>
                    <DateInput
                      source="startDate"
                      label="Start date"
                      validate={[required()]}
                    />
                    <DateInput
                      source="endDate"
                      label="End date"
                      validate={[required()]}
                    />
                  </>
                )}
                <NumberInput
                  source="rate"
                  label="Rate"
                  validate={[required()]}
                />
                <ReferenceInput
                  source="companyId"
                  validate={[required()]}
                  reference="Company"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source="officeId"
                  validate={[required()]}
                  reference="Office"
                >
                  <SelectInput optionText="address" />
                </ReferenceInput>
                <SelectInput
                  label="Inventory type"
                  validate={[required()]}
                  source="inventoryType"
                  choices={[
                    { id: 'single_person_desk', name: 'Single-person desk' },
                    { id: 'single_person_office', name: 'Single-person office' },
                    { id: 'multi_person_office', name: 'Multi-person office' },
                  ]}
                />
                <TextInput
                  label="Users"
                  source="users"
                  multiline
                  validate={[required()]}
                />
                <TextInput
                  label="Additional notes"
                  source="additionalNotes"
                  multiline
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </EditView>
      }
    </EditController>
  )
}

export const MembershipList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="inventoryType" />
        <TextField source="additionalNotes" />
        <TextField source="users" />
        <DateField source="startDate" options={{ timeZone: 'UTC' }} />
        <DateField source="endDate" options={{ timeZone: 'UTC' }}/>
        <NumberField source="rate" />
        <ReferenceField source="officeId" reference="Office">
          <TextField source="address" />
        </ReferenceField>
        <ReferenceField source="companyId" reference="Company">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
