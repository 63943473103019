import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Office from './WizardSteps/Office'
import Desk from './WizardSteps/Desk'
import Confirm from './WizardSteps/Confirm';

import {useDataProvider} from 'react-admin';
import { useAuthenticated } from 'react-admin';
import firebase from 'firebase';
import _ from 'lodash';
import {useGraphql} from '../hooks/useGraphql';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Configure Office', 'Configure Desk', 'Confirm'];
}

function getStepContent(stepIndex, props) {
  switch (stepIndex) {
    case 0:
      return <Office {...props} />
    case 1:
      return <Desk {...props} />
    case 2:
      return <Confirm {...props} />;
    default:
      return 'Unknown stepIndex';
  }
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const [loading, setLoading] = useState(true);
  const {getOrganizations} = useGraphql()
 
  
  const classes = useStyles();
  const dataProvider = useDataProvider();
  
  const [activeStep, setActiveStep] = React.useState(0);
  const [offices, setOffices] = React.useState([])
  const [desks, setDesks] = React.useState([])
  const steps = getSteps();
  const [organizations, setOrganizations] = useState([])
  
  useEffect(() => {
      
        getOrganizations().then(o => {
          console.log(o, 'organizations??')
           setOrganizations(o);
        })
      
  }, []);

  useEffect(() => {
    if(organizations.length > 0 ) {
      dataProvider.getList('offices', { pagination: { page: 1, perPage: 5 }, sort: { field: 'id', order: 'ASC' }, filter: { organizationId: organizations[0].id }, })
          .then(({ data }) => {
              setOffices(_.compact(data));
              
          })
          .catch(error => {
              console.error(error)
          })
    }
      
  }, [organizations]);

  useEffect(() => {
    if(offices.length > 0 ) {
      dataProvider.getList('desks', { pagination: { page: 1, perPage: 5 }, sort: { field: 'id', order: 'ASC' }, filter: { officeId: offices[0].id  }, })
        .then(({ data }) => {
            setDesks(_.compact(data));
            
        })
        .catch(error => {
  
            
        })
    }
    
  }, [offices]);

  useEffect(() => {
    if(offices.length > 0 && offices[0] && desks.length > 0 && desks[0] !== null) {
      setActiveStep(2)
    } else  if(offices.length > 0){
      setActiveStep(1)
    }
  }, [offices,  desks])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Paper className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep, {setOffices, setDesks, offices: _.compact(offices), desks: _.compact(desks), organizations })}
            {/* <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div> */}
          </div>
        )}
      </div>
    </Paper>
  );
}
