import React, { useState, useEffect, useContext, createContext } from 'react';
import { gql } from 'apollo-boost';
import fetch from 'node-fetch';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import moment from 'moment';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_ENDPOINT,
  fetch,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('flex-token');
    // return the headers to the context so httpLink can read them

    return {
        headers: {
            ...headers,

            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
});

// firebase.analytics();
const gqlContext = createContext();

// Provider hook that creates auth object and handles state
export function useProvideGql() {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [org, setOrg] = useState(null);

    const query = (query, variables) => {
        setLoading(true);
        return client
            .query({
                query: gql`
                    ${query}
                `,
                variables,
            })
            .then(result => {
                setLoading(false);
                return result.data;
            });
    };

    const mutate = (mutation, variables) => {
        setLoading(true);
        return client
            .mutate({
                mutation: gql`
                    ${mutation}
                `,
                variables,
            })
            .then(result => {
                setLoading(false);
                return result.data;
            });
    };

    const getOnboarding = () => {
      return query(`
        query {
            officesCount(column: ID)
            desksCount(column: ID)
        }`
      ).then(d => {
        return { officeCount:d.officesCount , deskCount: d.desksCount}
      })
    }

    const getUser = () => {
        return query(
            `query {
                currentUser {
                  id
                  uid
                  firstName
                  lastName
                  organizations {
                    id
                    name
                  }
                }
              }
              `
        ).then(({ currentUser: currentUser }) => {
            if (!currentUser) return;
            window.analytics && window.analytics.identify(currentUser.uid, {
                ...currentUser,
            });
            setUser(currentUser);
        });
    };

    const getOrganizations = () => {
        return query(`query {
        organizations {
          id
          logo
          name
          stripeAccountId
        }
      }`).then(d => d && d.organizations);
    };

    const getReservationBookingDates = reservationId => {
        return query(
            `query($reservationId:ID!){
                reservation(id:$reservationId) {
                  availabilities {
                    date
                  }
                }
              }
    `,
            { reservationId }
        ).then(d => d.reservation.availabilities);
    };

    const getReservationsCalendar = () => {
        return query(
            `query($yesterday:String){
              reservationsAvailabilities(date:$yesterday){
                availability {
                  date
                }
                reservation {
                  id
                  user {
                    firstName
                    lastName
                  }
                  desk {
                    type
                    id
                  }
                }
              }
            }
    `,
            {
                yesterday: moment().subtract(30, 'day').format("YYYY-MM-DD"),
            }
        ).then(d => (d ? d.reservationsAvailabilities : []));
    };

    const getOrg = id => {
        return query(
            `query ($id:Int){
      organizationById(id: $id) {
        id
        name
        stripeAccountId
      }
    }
    `,
            { id }
        ).then(d => setOrg(d.organizationById));
    };

    const statsQuery = () => {
        return query(
            `{
        officesCount(column: ADDRESS)
        desksCount(column: ID)
        reservationsCount(column: ID)
        reservationsSum(column: TOTAL)
      }
      `,
            {}
        ).then(data => {
            return {
                offices: data ? data.officesCount : 0,
                desks: data ? data.desksCount: 0,
                revenue: data
                    ? Number(data.reservationsSum)
                    : 0,
                reservations: data
                    ? data.reservationsCount
                    : 0,
            };
        });
    };
    // Return the user object and auth methods
    return {
        query,
        mutate,
        loading,
        statsQuery,
        getOrg,
        getUser,
        getReservationsCalendar,
        getReservationBookingDates,
        getOrganizations,
        getOnboarding,
        user,
        org,
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideGraphql({ children }) {
    const GQL = useProvideGql();
    return <gqlContext.Provider value={GQL}>{children}</gqlContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useGraphql = () => {
    return useContext(gqlContext);
};
