import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import StatCard from './components/StatCard';
import { useAuthenticated, Loading } from 'react-admin';
import { useProvideGql } from '../../hooks/useGraphql';
import { ReservationList } from '../../models/reservations';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useQuery, Error } from 'react-admin';
import OnboardingSteps from '../../components/OnboardingSteps';
import handleRedirect from '../../utils/onboardingRedirect'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment); // or globalizeLocalizer
let allViews = Object.keys(Views).map(k => Views[k]);
const events = [
    {
        id: 0,
        title: 'All Day Event very long title',
        allDay: true,
        start: new Date(2020, 6, 20),
        end: new Date(2020, 6, 20),
    },
];
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    });


export default () => {
    useAuthenticated();
    const [stats, setStats] = useState(null);
    const [reservations, setReservations] =  useState(null);
    const { statsQuery, getReservationsCalendar, loading, getOnboarding } = useProvideGql();

    useEffect(() => {
        getOnboarding().then(d => handleRedirect(d))
    }, [])

    useEffect(() => {
        getReservationsCalendar().then((r) => {

            const events = []
            r.map((arr,i)  => {
                events.push({
                    id: arr.reservation.id,
                    title: `${arr.reservation.desk.type} Booked`,
                    allDay: true,
                    start: moment(arr.availability.date),
                    end: moment(arr.availability.date),
                },)
            })
            setReservations(events)
        })
    },[])
    useEffect(() => {
        statsQuery().then(({ offices, desks, revenue, reservations }) => {
            setStats({
                office: {
                    type: 'Offices',
                    value: offices,
                    link: '/#/Office',
                },
                desk: {
                    type: 'Desks',
                    value: desks,
                    link: '/#/Desk',
                },
                revenue: {
                    type: 'Revenue',
                    value: `$${revenue || 0}`,
                    link: '/#/Reservation',
                },
                reservations: {
                    type: 'Reservations',
                    value: reservations,
                    link: '/#/Reservation',
                },
            });
        });
    }, []);

    if (!stats) {
        return <Loading />;
    }
    const initProps = {
        basePath: '/Reservation',
        hasCreate: false,
        hasEdit: false,
        title: 'FlexDesk Host',
        hasList: true,
        hasShow: false,
        exporter: false,
        location: { pathname: '/', search: '', hash: '', state: undefined },
        match: { path: '/', url: '/', isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: 'Reservation',
        perPage: 5,
    };

    return (
        <div>
            {/* <OnboardingSteps /> */}
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <StatCard {...stats.revenue} />
                </Grid>
                <Grid item xs={3}>
                    <StatCard {...stats.reservations} />
                </Grid>
                <Grid item xs={3}>
                    <StatCard {...stats.office} />
                </Grid>
                <Grid item xs={3}>
                    <StatCard {...stats.desk} />
                </Grid>
            </Grid>
            {reservations &&  <Grid >
                <h2>Reservation Calendar</h2>
                <Paper style={{height: 800, marginTop:20}}>

                <Calendar
                    events={reservations}
                    views={allViews}
                    step={60}
                    showMultiDayTimes
                    defaultDate={new Date()}
                    onSelectEvent={event => window.location = `/#/Reservation/${event.id}/show`}
                    components={{
                        timeSlotWrapper: ColoredDateCellWrapper,
                    }}
                    localizer={localizer}
                />
                </Paper>
            </Grid>}


            <h2 style={{ marginBottom: -50, marginTop: 50 }}>
                Recent Reservations
            </h2>
            <ReservationList {...initProps} />
        </div>
    );
};
