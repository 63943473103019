import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm } from 'react-final-form';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

function mapAmenities(amenities) {
  const s ={}
  amenities.map(a => s[a] = false) 

  return s
}

export default ({amenities, formData }) => {
    const form = useForm();
    const classes = useStyles();
    
    const [state, setState] = React.useState(mapAmenities(amenities));
    let halfwayThrough = Math.floor(amenities.length / 2)
    // or instead of floor you can use ceil depending on what side gets the extra data
    useEffect(() => {
      if(formData && formData.amenities) {
        let _s = { ...state}
        formData.amenities.map(a => {
          _s = { ..._s, [a]: true }
         
        })
        setState(_s);
      }
    },[formData])
    let aOne = amenities.slice(0, halfwayThrough);
    let aTwo = amenities.slice(halfwayThrough, amenities.length);
    const handleChange = (event) => {
        const _s = { ...state, [event.target.name]: event.target.checked }
        setState(_s);
        form.change('amenities', amenities.map(k => {if(_s[k]) return k}).filter(v => v))
    };

    return (<Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Amenities</FormLabel>
                <FormGroup>
                    {aOne.map(amenity => <FormControlLabel
                        key={amenity}
                        control={<Checkbox checked={state[amenity]} onChange={handleChange} name={amenity} />}
                        label={amenity}
                    />)}
                    
                </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
      <FormControl component="fieldset" className={classes.formControl}>
            
            <FormGroup>
                {aTwo.map(amenity => <FormControlLabel
                    key={amenity}
                    control={<Checkbox checked={state[amenity]} onChange={handleChange} name={amenity} />}
                    label={amenity}
                />)}
                
            </FormGroup>
    </FormControl>
      </Grid>
     
    </Grid>)
    
}