import React from 'react';
import { Route } from 'react-router-dom';
import SetupWizard from './pages/SetupWizard';
import PasswordResetRequest from './pages/PasswordResetRequest';
import Settings from './pages/Settings';
import Redirect from './pages/Redirect';

export default [
    <Route exact path="/get-started" component={SetupWizard} />,
    <Route exact path="/password-reset-request" noLayout component={PasswordResetRequest} />,
    <Route exact path="/settings" component={Settings} />,
    <Route exact noLayout path="/redirect" component={Redirect} />
    
];