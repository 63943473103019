import React from 'react';
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    TextField,
    DateField,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    ImageInput,
    SelectInput,
    ImageField,
    FormDataConsumer,
    required,
} from 'react-admin';
import ImageUpload from '../components/ImageUpload';
import AvailabilityModule from '../components/AvailabilityModule';
import Amenities from '../components/Amenities';
import Grid from '@material-ui/core/Grid';

export const DeskList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ImageField source="images.0" label="Image" />
            <TextField source="type" />
            <NumberField
                source="price"
                options={{ style: 'currency', currency: 'USD' }}
            />
            <NumberField source="count" />
            <TextField source="name" />
            <ReferenceField source="officeId" reference="Office">
                <TextField source="address" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export const DeskEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container spacing={3} style={{ width: '100%' }}>
                <Grid item xs={6} container direction={'column'}>
                    <TextInput source="name" validate={[required()]} />
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <NumberInput source="length" />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="height" />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="width" />
                        </Grid>
                    </Grid>

                    <NumberInput
                        source="count"
                        label="Quantity"
                        helperText="How many of this type of desk is in your office?"
                        validate={[required()]}
                    />
                    <NumberInput
                        source="price"
                        label="Daily Rental Price"
                        helperText="What does it cost to rent this desk for a day?"
                        validate={[required()]}
                    />

                    <ReferenceInput
                        source="officeId"
                        validate={[required()]}
                        reference="Office"
                    >
                        <SelectInput optionText="address" />
                    </ReferenceInput>
                    <SelectInput
                        validate={[required()]}
                        source="type"
                        choices={[
                            { id: 'Private Desk', name: 'Private Desk' },
                            { id: 'Shared Desk', name: 'Shared Desk' },
                            { id: 'Sofa', name: 'Sofa' },
                            { id: 'Cubicle', name: 'Cubicle' },
                            { id: 'Private Office', name: 'Private Office' },
                        ]}
                    />
                    <TextInput multiline source="description" />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {formDataProps => <ImageUpload {...formDataProps} />}
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {formDataProps => (
                            <Amenities
                                {...formDataProps}
                                amenities={[
                                    'Standing Desk',

                                    'Second Monitor',

                                    'Wireless Charger',
                                    'Ergonomic Chair',
                                    'Standing Desk Floor Mat',

                                    'Laptop Stand',
                                    'Ethernet Cable',
                                    'USB Outlets',
                                    'Extra Power Outlets',
                                ]}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                {/* <Grid xs={9}>
                    <FormDataConsumer>
                        {formDataProps => (
                            <AvailabilityModule {...formDataProps} />
                        )}
                    </FormDataConsumer>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Edit>
);

export const DeskCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <Grid container spacing={3} style={{ width: '100%' }}>
                <Grid item xs={6} container direction={'column'}>
                    <TextInput source="name" validate={[required()]} />
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <NumberInput source="length" />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="height" />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="width" />
                        </Grid>
                    </Grid>

                    <NumberInput
                        source="count"
                        label="Quantity"
                        helperText="How many of this type of desk is in your office?"
                        validate={[required()]}
                    />
                    <NumberInput
                        source="price"
                        label="Daily Rental Price"
                        helperText="What does it cost to rent this desk for a day?"
                        validate={[required()]}
                    />

                    <ReferenceInput
                        source="officeId"
                        validate={[required()]}
                        reference="Office"
                    >
                        <SelectInput optionText="address" />
                    </ReferenceInput>
                    <SelectInput
                        validate={[required()]}
                        source="type"
                        choices={[
                            { id: 'Private Desk', name: 'Private Desk' },
                            { id: 'Shared Desk', name: 'Shared Desk' },
                            { id: 'Sofa', name: 'Sofa' },
                            { id: 'Cubicle', name: 'Cubicle' },
                            { id: 'Private Office', name: 'Private Office' },
                        ]}
                    />
                    <TextInput
                        source="description"
                        multiline
                        validate={[required()]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {formDataProps => <ImageUpload {...formDataProps} />}
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {formDataProps => (
                            <Amenities
                                {...formDataProps}
                                amenities={[
                                    'Standing Desk',

                                    'Second Monitor',

                                    'Wireless Charger',
                                    'Ergonomic Chair',
                                    'Standing Desk Floor Mat',

                                    'Laptop Stand',
                                    'Ethernet Cable',
                                    'USB Outlets',
                                    'Extra Power Outlets',
                                ]}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                {/* <Grid xs={9}>
                    <FormDataConsumer>
                        {formDataProps => (
                            <AvailabilityModule {...formDataProps} />
                        )}
                    </FormDataConsumer>
                </Grid> */}
            </Grid>
        </SimpleForm>
    </Create>
);
