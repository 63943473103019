import React, {useEffect} from 'react';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import { useForm, useFormState } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flex:1,
  },
  toggle: {
    '&$selected': {
        background: green[400],
        color: 'white',
        boxShadow: 'none',
      },
  },
  selected: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  
}));

export default function CenteredGrid({formData} ) {
  let availabilityTemplate = {}
  if(formData) {
    ({ availabilityTemplate = {}} = formData)
  }
  const classes = useStyles();
  const [formats, setFormats] = React.useState(() => availabilityTemplate.daysOfWeek || ['Monday', 'Tuesday', 'Wednesday', 'Thursday','Friday']);
  const form = useForm()

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday','Friday','Saturday','Sunday']
  const handleFormat = (event, newFormats) => {
    console.log(newFormats, 'newFormats?')
    setFormats(newFormats);
    form.change('availabilityTemplate', { daysOfWeek:  newFormats })
  };
  useEffect(() => {
    form.change('availabilityTemplate', { daysOfWeek:  formats })
  }, [formats])
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <h3>What days of the week is this desk available?</h3>
            <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting">
                {days.map(day => 
                <ToggleButton key={day} value={day} aria-label={day} classes={{
                    root: classes.toggle,
                    selected: classes.selected, // class name, e.g. `disabled-x`
                  }}>
                    <p>{day}</p>
                </ToggleButton>)}
            
            </ToggleButtonGroup>
        </Grid>
        
      </Grid>
    </div>
  );
}
