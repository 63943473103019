import React, { useEffect, useState } from 'react'
import { useGraphql}from '../../hooks/useGraphql'
import moment from 'moment'

export default (formData) => {
    const {getReservationBookingDates, loading} = useGraphql()
    const [bookings, setBookings] = useState([])
    useEffect(() => {
        getReservationBookingDates(formData.record.id).then(d => {
            setBookings(d.map(b => moment(b.date).format('MM/DD/YYYY')))
        })
    },[])
    return (<div>
        <p>Dates booked:</p>
        <ul>
        {bookings.map(b => (<li>{b}</li>))}
        </ul>

    </div>)
}