import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    TextField,
    BooleanInput,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer,
    required,
    regex,
    email,
    Confirm,
    DeleteButton,
    Toolbar,
    SaveButton
} from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FormHelperText } from '@material-ui/core';
import AddressAutoComplete from '../../components/AddressAutocomplete';
import ImageUpload from '../../components/ImageUpload';
import Amenities from '../../components/Amenities';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PasswordInput } from 'react-admin';
import HoursOfOperation from '../../components/HoursOfOperation';
import { withStyles } from '@material-ui/core';
const validateEmail = email();
const validatePhoneNumber = regex(
    /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
    'Must be a valid phone number'
);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        color: '#f1faee',
    },
    header: {
        backgroundColor: '#457b9d',
        fontWeight: 'bold',
        color: '#f1faee',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        color: '#f1faee',
    },
    buffer: {
        paddingBottom: 20,
    },
    white: {
        color: 'white',
    },
}));

function detailSectionDone(fd) {
    return fd.address && fd.pocEmail && fd.organizationId && fd.pocPhone;
}

function houseRulesDone(fd) {
    return (
        fd.culture &&
        fd.sanitaryPolicy &&
        fd.wifiUsername &&
        fd.wifiPassword &&
        fd.amenitiesAccess
    );
}

function arrivalDone(fd) {
    return (
        fd.parking && fd.buildingAccess && fd.buildingEntrance && fd.seatAccess
    );
}

function bookingRulesDone(fd) {
    return fd.minimumDaysRental && fd.minimumConsecutive;
}
export const OfficeEdit = props => {
    const [expanded, setExpanded] = React.useState('office-details');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
    const classes = useStyles();
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const toolbarStyles = {
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    };

    const CustomToolbar = withStyles(toolbarStyles)(props => (
      <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
      </Toolbar>
    ));

    return (
        <Edit {...props}>
            <SimpleForm toolbar={
                <CustomToolbar />
              }>
                <Grid container spacing={3} style={{ width: '100%' }}>
                    <Grid item xs={12} container direction={'column'}>
                        <ExpansionPanel
                            expanded={expanded === 'office-details'}
                            onChange={handleChange('office-details')}
                        >
                            <ExpansionPanelSummary
                                expandIcon={
                                    <ExpandMoreIcon className={classes.white} />
                                }
                                aria-controls="office-detailsbh-content"
                                id="office-detailsbh-header"
                                className={classes.header}
                            >
                                <FormDataConsumer>
                                    {({ formData }) =>
                                        detailSectionDone(formData) ? (
                                            <CheckCircleIcon
                                                style={{ marginRight: 10 }}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                style={{ marginRight: 10 }}
                                            />
                                        )
                                    }
                                </FormDataConsumer>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.heading}
                                >
                                    Office Details
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    General Office Details
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid
                                    container
                                    spacing={3}
                                    style={{ width: '100%' }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction={'column'}
                                    >
                                        <FormDataConsumer>
                                            {formDataProps => (
                                                <ImageUpload
                                                    {...formDataProps}
                                                />
                                            )}
                                        </FormDataConsumer>
                                        <TextInput
                                            source="displayName"
                                            label="Display Name"
                                        />
                                        <TextInput
                                            source="address2"
                                            label="Address line 2"
                                        />
                                        <TextInput
                                            source="pocPhone"
                                            label="Point of Contact Phone"
                                            validate={[
                                                required(),
                                                validatePhoneNumber,
                                            ]}
                                        />
                                        <TextInput
                                            source="pocEmail"
                                            label="Point of Contact Email"
                                            validate={[
                                                required(),
                                                validateEmail,
                                            ]}
                                        />

                                        <TextInput
                                            multiline
                                            source="description"
                                            label="Office Description"
                                            helperText="Write a pithy sentence or two of your office. This information will be publicly available on your listing page"
                                        />

                                        <FormDataConsumer>
                                            {formDataProps => (
                                                <Amenities
                                                    {...formDataProps}
                                                    amenities={[
                                                        'Free Coffee',
                                                        'Free Snacks',
                                                        'Free Printer Access',
                                                        'Refrigerator Access',
                                                        'Free Parking',
                                                        'Bathrooms',
                                                        'Microwave Access',
                                                        'Beautiful View',
                                                        'Private Areas',
                                                        'Bike Parking',
                                                        'Pet Friendly',
                                                        'Outdoor Space',
                                                        'ADA Compliant',
                                                        'Cold Brew',
                                                        'Gym',
                                                        'Patio',
                                                        '1GB/s Wifi',
                                                        'Stand up desks',
                                                    ]}
                                                />
                                            )}
                                        </FormDataConsumer>
                                        <ReferenceInput
                                            label="Organization"
                                            source="organizationId"
                                            reference="Organization"
                                        >
                                            <SelectInput optionText="name" />
                                        </ReferenceInput>
                                        <FormDataConsumer>
                                            {formDataProps => (
                                                <HoursOfOperation
                                                    {...formDataProps}
                                                />
                                            )}
                                        </FormDataConsumer>
                                        <BooleanInput
                                            helperText="This works best for hosts with auto-lock or self-unlock entrances."
                                            label="Allow guests to arrive and depart anytime during their rental day"
                                            source="openAllDay"
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                        >
                            <ExpansionPanelSummary
                                expandIcon={
                                    <ExpandMoreIcon className={classes.white} />
                                }
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className={classes.header}
                            >
                                <FormDataConsumer>
                                    {({ formData }) =>
                                        houseRulesDone(formData) ? (
                                            <CheckCircleIcon
                                                style={{ marginRight: 10 }}
                                            />
                                        ) : (
                                            <Tooltip title="Click here to finish the house rules section">
                                                <ErrorIcon
                                                    style={{ marginRight: 10 }}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </FormDataConsumer>
                                <Typography className={classes.heading}>
                                    House Rules
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    Manage guest expectations
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid
                                    container
                                    direction={'column'}
                                    style={{ width: '80%' }}
                                >
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="culture"
                                        label="Office Culture"
                                        helperText="Give your guests some details on the office vibes. Can I take a call in the common area or at my seat? Is there a recommended dress code? (E.g., guests sitting in the main area are welcome to take a call, but please use your inside voice). This information will be publicly available on your listing page."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="amenitiesAccess"
                                        label="Amenities Access"
                                        helperText="Give your guests some details on what they're welcome to use. Are the coffee & snacks free? Do I need to bring my own cup? This information will be distributed over email after booking."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="sanitaryPolicy"
                                        label="Sanitary Policy"
                                        helperText="Give your guests some details on your sanitation procedures, including supplies available on-site, how to socially distance, and whether there's a cleaning crew. This information will be publicly available on your listing page."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        source="wifiUsername"
                                        label="Wifi Username"
                                        helperText="This information will only be shared with a guest on booking."
                                    />
                                    <PasswordInput
                                        className={classes.buffer}
                                        source="wifiPassword"
                                        label="Wifi Password"
                                        helperText="This information will only be shared with a guest on booking."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="houseRules"
                                        label="Anything else guests should know?"
                                        helperText="Have anything else to tell your guest ahead of time? (E.g., there is no staff member on-site so please contact the Host with any questions; there is a sheet with additional information posted inside the front door)"
                                    />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel
                            expanded={expanded === 'panel3'}
                            onChange={handleChange('panel3')}
                        >
                            <ExpansionPanelSummary
                                expandIcon={
                                    <ExpandMoreIcon className={classes.white} />
                                }
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                className={classes.header}
                            >
                                <FormDataConsumer>
                                    {({ formData }) =>
                                        arrivalDone(formData) ? (
                                            <CheckCircleIcon
                                                style={{ marginRight: 10 }}
                                            />
                                        ) : (
                                            <Tooltip title="Click here to finish the arrival instructions">
                                                <ErrorIcon
                                                    style={{ marginRight: 10 }}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </FormDataConsumer>
                                <Typography className={classes.heading}>
                                    Arrival Instructions
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    Help your guests find their workspace
                                    smoothly
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid
                                    container
                                    direction={'column'}
                                    style={{ width: '80%' }}
                                >
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="parking"
                                        label="Parking"
                                        helperText="Give your guests some details on where to park their car, and if there's a cost to do so. Be sure to add any additional logistics (e.g., parking pass is at your desk, put that on the car dashboard). This information will be publicly available on your listing page."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="buildingEntrance"
                                        label="Building Entrance"
                                        helperText="Give your guests some details on what the exterior of the building looks like, and how they'll know they are at the correct entrance. This information will be publicly available on your listing page."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="buildingAccess"
                                        label="Building Access"
                                        helperText="Give your guests some details on how they should get inside the building. (E.g., enter door code, call to be let in, knock, etc.). This information will be distributed over email after booking."
                                    />
                                    <TextInput
                                        className={classes.buffer}
                                        multiline
                                        source="seatAccess"
                                        label="Seat Access"
                                        helperText="Give your guests some details on how they find their workspace (e.g., greeter will escort you, sit anywhere, look for the seat number that you receive in separate text). This information will be distributed over email after booking."
                                    />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel
                            expanded={expanded === 'panel4'}
                            onChange={handleChange('panel4')}
                        >
                            <ExpansionPanelSummary
                                expandIcon={
                                    <ExpandMoreIcon className={classes.white} />
                                }
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                                className={classes.header}
                            >
                                <FormDataConsumer>
                                    {({ formData }) =>
                                        bookingRulesDone(formData) ? (
                                            <CheckCircleIcon
                                                style={{ marginRight: 10 }}
                                            />
                                        ) : (
                                            <Tooltip title="Click here to finish the booking rules">
                                                <ErrorIcon
                                                    style={{ marginRight: 10 }}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </FormDataConsumer>
                                <Typography className={classes.heading}>
                                    Booking Rules
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    Set how Guests are allowed to book
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid
                                    container
                                    direction={'column'}
                                    style={{ width: '80%' }}
                                >
                                    <NumberInput
                                        className={classes.buffer}
                                        max={20}
                                        min={1}
                                        source="minimumDaysRental"
                                        label="Minimum number of days guests will be required to rent."
                                        helperText="We recommend setting the number to 1 day to maximize revenue opportunity."
                                    />
                                    <BooleanInput
                                        helperText="We recommend selecting 'No' to maximize revenue opportunity"
                                        className={classes.buffer}
                                        label="Will your guests be required to stay consecutive days in order to meet the minimum requirement?"
                                        source="minimumConsecutive"
                                    />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
