import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddressAutocomplete from '../../components/AddressAutocomplete'
import ImageUpload from '../../components/ImageUpload'
import Amenities from '../../components/Amenities'  
import  {FormDataConsumer} from 'react-admin'
import { Form, Field } from 'react-final-form'
import { useMutation, useQuery, Loading, Error, useDataProvider } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        
        minWidth: 300,
        flex:1
      },
    },
    fill: {
        flex:1
    }
  }));

const required = value => (value ? undefined : 'Required')


export default function CenteredGrid({ setOffices, organizations }) {
    const dataProvider = useDataProvider();
   
    const classes = useStyles();
    const [error, setError] = useState();
    
    
    const [mutate, { loading:loading2, data:office }] = useMutation();
    const create = payload => mutate({
        type: 'create',
        resource: 'offices',
        payload: {data:payload}
    })
    const onSubmit = async (data) => {
        console.log(data, 'data?')
        data.organizationId = organizations[0].id
        await create(data)
       
    }
    useEffect(() => {
        if(office){
            console.log(office, 'office')
            setOffices([office])
        }
    }, office)
    const validate = () => {

    }
    
    if (error) return <Error />;
  return (  
    <div className={classes.root}>
        <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={3}>
            <Grid item xs={6}>
                <AddressAutocomplete />
                <Field name="address2" >
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Address line 2"
                            fullWidth
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'Suite #, floor #, etc.,'}
                            variant="filled"
                        />
                    )}
                </Field>
                <Field name="description" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Description"
                            fullWidth
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'How would you describe this office?'}
                            variant="filled"
                        />
                    )}
                </Field>
                <Field name="pocName" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Point of contact"
                            fullWidth
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'Who will be in charge of bookings in this office?'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="pocEmail" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            fullWidth
                            label="Point of contact email"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'POC Email'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="pocPhone" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            fullWidth
                            label="Point of contact phone"
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'POC Phone'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="arrivalInstructions" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Arrival instructions"
                            multiline
                            fullWidth
                            rowsMax={4}
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'How should guests enter the office?'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="houseRules" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="House Rules"
                            multiline
                            fullWidth
                            rowsMax={4}
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'How should guests behave in the office?'}
                            variant="filled"
                        />
                    )}
                </Field>

                <Field name="sanitaryPolicy" validate={required}>
                    {({ input, meta }) => (
                        <TextField
                        className={classes.fill}
                            error={meta.error && meta.touched}
                            id="filled-error-helper-text"
                            label="Sanitary Policies"
                            multiline
                            fullWidth
                            rowsMax={4}
                            {...input}
                            helperText={meta.error && meta.touched ? 'Required Field': 'What does the workspace do to keep guests safe? what should guests do?'}
                            variant="filled"
                        />
                    )}
                </Field>
            </Grid>
            <Grid item xs={6}>
                <FormDataConsumer>
                    {formDataProps => (
                        <ImageUpload {...formDataProps} />
                    
                    )}
                    
                </FormDataConsumer>
                <Amenities amenities={[
                    'Coffee',
                    'Bathrooms',
                    'Elevator',
                    'Beautiful View', 
                    'Modern Furniture', 
                    'Beer', 
                    'Cold Brew', 
                    'Gym', 
                    'Patio',
                    '1GP/s Wifi',
                    'Stand up desks'
                ]}/>
            </Grid>
        </Grid>
        <Button size="medium" type="submit" variant="contained" color="primary" className={classes.margin}>
          Save Office
        </Button>
        </form>
        )} />
    </div>
  );
}
