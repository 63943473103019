import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/Cancel';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    helper: {
        zIndex: 99,
    },
    gridList: {
        width: '100%',
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: 'white',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));
const SortableItem = SortableElement(({ img, removeImage }) => {
    const classes = useStyles();
    return (
        <GridListTile
            style={{ width: 200, height: 200 }}
            key={img}
            cols={1}
        >
            <img
                src={img.replace('_1000x1000','')}
            />
            <GridListTileBar
                title={'Delete Image'}
                classes={{
                    root: classes.titleBar,
                    title: classes.title,
                }}
                actionIcon={
                    <IconButton onClick={() => removeImage(img)}>
                        <StarBorderIcon className={classes.title} />
                    </IconButton>
                }
            />
        </GridListTile>
    );
});

const SortableList = SortableContainer(({ images, removeImage }) => {
    const classes = useStyles();
    return (
        <GridList className={classes.gridList} cols={4}>
            {images.map((value, index) => (
                <SortableItem
                    key={`item-${value}`}
                    index={index}
                    img={value}
                    removeImage={removeImage}
                />
            ))}
        </GridList>
    );
});

export default function SingleLineGridList({ images, setImages }) {
    const classes = useStyles();
    const removeImage = img => {
        console.log(images, img, 'what be?')
        setImages(images.filter(i => i !== img));
    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setImages(arrayMove(images, oldIndex, newIndex));
    };
    

    //   const [images, setImages] = useState(formData.images)
    return (
        <div className={classes.root}>
            <SortableList axis={'xy'} distance={1} helperClass={classes.helper} images={images} onSortEnd={onSortEnd} removeImage={removeImage} />
        </div>
    );
}
