import { toast } from 'react-toastify';

export default function handleRedirect({officeCount, deskCount }) {

    if(officeCount === 0) {
        toast.success('Welcome to FlexDesk! Get started by creating an Office.')
        return window.location = '/#/Office/create'
    }

    if(deskCount === 0) {
        toast.success('Welcome back to FlexDesk! Continue onboarding by creating a Desk.')
        return window.location = '/#/Desk/create'
    }

    return window.location = '/#/'
}