import React from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import ReservationIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import { Helmet } from 'react-helmet';

// import UserIcon from '@material-ui/icons/Group';
import {
    Admin,
    Resource,
    ListGuesser,
    EditGuesser,
    fetchUtils,
    ShowGuesser,
} from 'react-admin';
import { createHttpLink } from 'apollo-link-http';
import fetch from 'node-fetch';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import { setContext } from 'apollo-link-context';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

// The following components are created when following the react-admin tutorial
import { OfficeList, OfficeShow } from './models/offices/index.js';
import { OfficeCreate } from './models/offices/create';
import { OfficeEdit } from './models/offices/update';

import { DeskList, DeskEdit, DeskCreate, DeskShow } from './models/desks';
import { ReservationList, ReservationShow } from './models/reservations';
import { UserShow, UserList } from './models/customers';
import { MembershipList, MembershipCreate, MembershipEdit } from './models/memberships';

// import { UserList } from './users';
// import Dashboard from './Dashboard';
import authProvider from './authProvider';
import customRoutes from './routes';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Layout from './Layout';

import { ProvideAuth } from './hooks/useAuth';
import { ProvideGraphql } from './hooks/useGraphql';
import * as Sentry from '@sentry/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Sentry.init({
    dsn:
        'https://77736555502b4160918034182b37d6d3@o407916.ingest.sentry.io/5277861',
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_ENDPOINT,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('flex-token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});


const defaultOptions = {
  watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
  },
  query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
  },
};

const App = () => {
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
  });

  const [dataProvider, setDataProvider] = React.useState(null);
  React.useEffect(() => {
    buildGraphQLProvider({ client })
    .then(graphQlDataProvider => setDataProvider(() => graphQlDataProvider))
  }, [])

  if (!dataProvider) {
    return <div>Loading</div>
  }

  return (
    <ProvideAuth>
        <ProvideGraphql>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Flexdesk Host</title>
                <script>
                    {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
                analytics.load("aP7aHNSRZbUZypGbpNHB3ufrBReBGl0e");
                analytics.page();
                }}();`}
                </script>
            </Helmet>
            <Admin
                title={'FlexDesk Host Admin'}
                dashboard={Dashboard}
                layout={Layout}
                loginPage={Login}
                dataProvider={dataProvider}
                authProvider={authProvider}
                customRoutes={customRoutes}
            >
                <Resource
                    options={{ label: 'Offices' }}
                    icon={BusinessIcon}
                    name="Office"
                    list={OfficeList}
                    edit={OfficeEdit}
                    create={OfficeCreate}
                />
                <Resource
                    options={{ label: 'Desks' }}
                    icon={EventSeatIcon}
                    name="Desk"
                    list={DeskList}
                    edit={DeskEdit}
                    create={DeskCreate}
                />
                <Resource
                    options={{ label: 'Reservations' }}
                    icon={ReservationIcon}
                    name="Reservation"
                    edit={ReservationShow}
                    list={ReservationList}
                />
                <Resource
                    options={{ label: 'Customers' }}
                    icon={PersonIcon}
                    name="User"
                    edit={UserShow}
                    list={UserList}
                />
                <Resource name="Organization" />
                <Resource name="Company" />
                <Resource
                    options={{ label: 'Memberships' }}
                    icon={PersonIcon}
                    name="Membership"
                    list={MembershipList}
                    create={MembershipCreate}
                    edit={MembershipEdit}
                />
            </Admin>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </ProvideGraphql>
    </ProvideAuth>
  )
};

export default App;
