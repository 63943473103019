import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import _ from 'lodash';

const useStyles = makeStyles(theme  => ({
    root: {
        flexGrow: 1,
        flex:1,
        width: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    width: 345
  },
  media: {
    height: 140,
  },
}));


export default ({offices, desks}) =>  {
    const classes = useStyles();
    console.log(offices, desks, 'of,d')
    if(offices.length === 0 || desks.length === 0) return (<div></div>)
    const Desk  = ()  => 
        (<Card className={classes.card}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image={desks[0].images[0]}
                title={desks[0].address}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                {desks[0].type}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                {desks[0].description}
                <Typography  variant="body" component="p">
                Available on: <br></br>
                
                </Typography>
                {desks[0].availabilityTemplate.daysOfWeek.map(d => <Chip label={d} />)} 
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" onClick={() => window.location = `/#/desks/${desks[0].id}`} color="primary">
                    Edit
                </Button>
                <Button size="small" onClick={() => window.location = '/#/desks/create'} variant="outlined" color="primary">
                    Create Another
                </Button>
            </CardActions>
        </Card>)
    const Office  = ()  => 
        (<Card className={classes.card}>
            <CardActionArea>
            <CardMedia
                className={classes.media}
                image={offices[0].images[0]}
                title={offices[0].address}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                {offices[0].address}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                <b>Point of contact</b><br></br>
                {offices[0].pocName}<br></br>
                {offices[0].pocEmail}<br></br>
                {offices[0].pocPhone}<br></br>
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" onClick={() => window.location = `/#/offices/${offices[0].id}`} color="primary">
                    Edit
                </Button>
                <Button size="small" onClick={() => window.location = '/#/offices/create'} variant="outlined" color="primary">
                    Create Another
                </Button>
            </CardActions>
        </Card>)
      
        return (
            <div className={classes.root}>
                <Grid container
                        direction="row"
                        justify="center"
                        className={classes.padding}
                        alignItems="center" >
                         
                         <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location = '/#/'}
                            className={classes.button}
                            startIcon={<HomeWorkIcon />}
                        >
                            Done! Go to Dashboard
                        </Button>
                </Grid>
               
              <Grid container spacing={3} style={{marginBottom: 25, marginTop: 20}}>
                <Grid item xs={6}>
                    <Grid container
                        direction="column"
                        justify="center"
                        className={classes.padding}
                        alignItems="center" >
                            <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom:5}}>Created Office</Typography>
                            <Office />
                    </Grid>
                </Grid>
                <Grid item xs={6} >
                    <Grid container
                        direction="column"
                        justify="center"
                        className={classes.padding}
                        alignItems="center" >
                            <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom:5}}>Created Desk</Typography>
                            <Desk />
                    </Grid>
                </Grid>
              </Grid>
            </div>
          );
    
}