import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { useForm } from 'react-final-form';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 10,
    },
    paper: {
        height: 160,
        width: 150,
    },
    control: {
        padding: theme.spacing(2),
    },
    textField: {
        padding: 5,
    },
}));

export default function SpacingGrid({ formData }) {
    const [hours, setHours] = useState(formData.hoursOfOperation || {});
    const form = useForm();
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();
    const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ];
    useEffect(() => {
        console.log(hours, 'hours?');
        if (hours) form.change('hoursOfOperation', hours);
    }, [hours]);

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                    Hours of operation
                </Typography>
                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 10 }}
                    spacing={spacing}
                >
                    {days.map(value => (
                        <Grid key={value} item>
                            <Paper className={classes.paper}>
                                <Grid container justify="center">
                                    <Chip
                                        label={value}
                                        color={
                                            hours[value] &&
                                            hours[value]['opens']
                                                ? moment(
                                                      hours[value]['opens'],
                                                      ['h:m a', 'H:m']
                                                  ).isValid()
                                                    ? 'secondary'
                                                    : 'default'
                                                : 'default'
                                        }
                                        style={{ marginBottom: 5 }}
                                    />
                                    <TextField
                                        id="time"
                                        label="Opens at"
                                        type="time"
                                        defaultValue={
                                            hours[value] &&
                                            hours[value]['opens']
                                                ? moment(
                                                      hours[value]['opens'],
                                                      ['h:m a', 'H:m']
                                                  ).format('HH:mm')
                                                : '-'
                                        }
                                        className={classes.textField}
                                        onChange={a =>
                                            setHours({
                                                ...hours,
                                                [value]: {
                                                    ...hours[value],
                                                    opens: a.target.value,
                                                },
                                            })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 1800,
                                        }}
                                    />
                                    <TextField
                                        id="time"
                                        label="Closes at"
                                        type="time"
                                        onChange={a =>
                                            setHours({
                                                ...hours,
                                                [value]: {
                                                    ...hours[value],
                                                    closes: a.target.value,
                                                },
                                            })
                                        }
                                        defaultValue={
                                            hours[value] &&
                                            hours[value]['closes']
                                                ? moment(
                                                      hours[value]['closes'],
                                                      ['h:m a', 'H:m']
                                                  ).format('HH:mm')
                                                : '-'
                                        }
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 1800,
                                        }}
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
