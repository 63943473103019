import React from 'react'
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    TextField,
    DateField,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    Show,
    SimpleShowLayout,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer
} from 'react-admin'
import BookingDates from '../components/CustomFields/BookingDates'
export const ReservationShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="confirmationNumber" />
            <NumberField options={{ style: 'currency', currency: 'USD' }} source="subtotal" />
            <NumberField options={{ style: 'currency', currency: 'USD' }} source="tax" />
            <NumberField options={{ style: 'currency', currency: 'USD' }} source="total" />
            <ReferenceField source="userId" reference="User"><TextField source="firstName" /></ReferenceField>
            <ReferenceField source="deskId" reference="Desk"><TextField source="type" /></ReferenceField>
            <ReferenceField source="officeId" reference="Office"><TextField source="address" /></ReferenceField>
            <BookingDates {...props}/>)
            <TextField source="notes" />
        </SimpleShowLayout>
    </Show>
);

export const ReservationList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="confirmationNumber" />
            <ReferenceField source="userId" reference="User"><TextField source="firstName" /></ReferenceField>
            <ReferenceField source="deskId" reference="Desk"><TextField source="type" /></ReferenceField>
            <NumberField options={{ style: 'currency', currency: 'USD' }} source="total" />

            <ReferenceField source="officeId" reference="Office"><TextField source="address" /></ReferenceField>
            <TextField source="notes" />
        </Datagrid>
    </List>
);