import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useInput, required, } from 'react-admin';
import { useForm } from 'react-final-form';
import AddressParser from "parse-google-address";

export class GoogleAddressParser {
    address = {};

    constructor(address_components) {
        this.address_components = address_components
      this.parseAddress();
    }

    parseAddress() {
      if (!Array.isArray(this.address_components)) {
        throw Error('Address Components is not an array');
      }

      if (!this.address_components.length) {
        throw Error('Address Components is empty');
      }

      for (let i = 0; i < this.address_components.length; i++) {
        const component = this.address_components[i];

        if (this.isStreetNumber(component)) {
          this.address.street_number = component.long_name;
        }

        if (this.isStreetName(component)) {
          this.address.street_name = component.long_name;
        }

        if (this.isCity(component)) {
          this.address.city = component.long_name;
        }

        if (this.isCountry(component)) {
          this.address.country = component.long_name;
        }

        if  (this.isState(component)) {
          this.address.state = component.long_name;
        }

        if (this.isPostalCode(component)) {
          this.address.postal_code = component.long_name;
        }
      }
    }

    isStreetNumber(component) {
      return component.types.includes('street_number');
    }

    isStreetName(component) {
      return component.types.includes('route');
    }

    isCity(component) {
      return component.types.includes('locality') || component.types.includes('sublocality');
    }

    isState(component) {
      return component.types.includes('administrative_area_level_1');
    }

    isCountry(component) {
      return component.types.includes('country');
    }

    isPostalCode(component) {
      return component.types.includes('postal_code');
    }

    result() {
      return this.address;
    }
  }

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps(props) {
    const form = useForm();

  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);



  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAt8Uc3qID1aA4FXdt8k2WM1UgjSa8q-Co&libraries=places&types=geocode',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const handleChange = (event) => {
    console.log(event.target.value, 'input value?')
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

    // const {
    //     input: { name, onChange },
    //     meta: { touched, error },
    //     isRequired
    // } = useInput(props);
  const onChange = (a,b) => {

      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ 'address': b.description }, function(results, status) {
        if (status === 'OK') {
          console.log(results[0].geometry.location)
          const address = new GoogleAddressParser(results[0].address_components).result();

            form.change('address', b.description )
            form.change('geocoordinates', `(${results[0].geometry.location.lat()}, ${results[0].geometry.location.lng()})`)
            form.change('state',address.state)
            form.change('city',address.city)
            form.change('zip',address.postal_code)
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });

  }
  return (
    <Autocomplete
      id="google-map-demo"

      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete='off'
      includeInputInList
      renderInput={(params) => (
        <TextField
          {...params}
          label="Office Location"
          variant="outlined"
          fullWidth
          autoComplete='off'
          name="PreventChromeAutocomplete"
          onChange={handleChange}
        />
      )}
      onChange={onChange}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
