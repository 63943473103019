import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Loading } from 'react-admin';
import firebase from 'firebase';
import {useGraphql} from '../hooks/useGraphql'
import { parse } from 'query-string';
import handleRedirect from '../utils/onboardingRedirect'

export default props => {
    const { at } = parse(props.location.search);
    const {getOnboarding} = useGraphql()
   
    useEffect(() => {
        if (at) {
            firebase
                .auth()
                .signInWithCustomToken(at)
                .then(result => {
                    firebase
                        .auth()
                        .currentUser.getIdToken(true)
                        .then(token => {
                            localStorage.setItem('flex-token', token);
                            // window.location = '/#/get-started';
                            getOnboarding().then(d => handleRedirect(d))
                            
                        })
                        .catch(err => {
                            console.error(err);
                            window.location = '/#/login';
                        });
                });
        } else {
            window.location = '/#/login';
        }
    }, []);
    return (
        <Grid container justify="center">
            <Loading
                loadingPrimary="Welcome to FlexDesk!"
                loadingSecondary="Just a moment! we're setting up your workspace."
            />
        </Grid>
    );
};
