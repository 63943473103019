import React from 'react'
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    TextField,
    DateField,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer,
    required,
    regex,
    email,
} from 'react-admin'
import AddressAutoComplete from '../../components/AddressAutocomplete'
import ImageUpload from '../../components/ImageUpload'
import Amenities from '../../components/Amenities'
const validateEmail = email();
const validatePhoneNumber = regex(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, 'Must be a valid phone number');


export const OfficeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
        <TextField source="id" />
            <ImageField source="images.0"  label="Image"/>
            
            <TextField source="address" />
           
           
            <TextField source="pocPhone" />
   
            <TextField source="pocEmail" />
            {/* <ReferenceField source="organizationId" reference="organizations"><TextField source="id" /></ReferenceField> */}
        </Datagrid>
    </List>
);