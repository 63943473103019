import React from 'react';
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    TextField,
    DateField,
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    Show,
    SimpleShowLayout,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer,
} from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="firstName" />
            <TextField source="lastName" />
        </Datagrid>
    </List>
);

export const UserShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="firstName" />
            <TextField source="lastName" />
        </SimpleShowLayout>
    </Show>
);
